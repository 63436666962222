.h3 {
  /* text-align: start; */
  margin: 0;
}

.desc {
  /* text-align: start; */
}

.actions {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.actions a {
  margin: 0 2rem;
  color: rgb(60,60,60);
}
