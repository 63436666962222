.image {
  width: 80%;
  box-shadow: 0 0 3px 3px rgb(120, 120, 120);
  margin-block-end: 2rem;
}

.text {
  width: 60%;
  margin: 0 auto;
  margin-block-start: 2rem;
  margin-block-end: 1rem;
}
