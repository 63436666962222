.link {
  text-decoration: none;
  color: rgb(60, 60, 60);
}

.link:hover {
  color: rgb(30, 30, 30);
}

.code {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.code p {
  margin: 0 2rem;
}
