.footer {
  width: 100%;
  min-width: 400px;
  position: relative;
  bottom: 0;
  margin: 0rem auto;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: rgb(150,150,150);
  font-size: 1.2rem;
  bottom: 0;
}

.link {
  text-decoration: none;
  color: inherit;
}
