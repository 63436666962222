.header {
  width: 100%;
  min-width: 400px;
  margin: 0rem auto;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header a{
  padding: 0.1rem 0.6rem;
}

.a {
  margin-inline-end: 2rem;
}

.last_a {
  text-decoration: none;
  color: inherit;
  margin-inline-end: 0;
}

.link {
  text-decoration: none;
  color: inherit;
}

.header a:hover{
  border-radius: 3px;
  background-color: gray;
  color: whitesmoke;
}
