.card {
  background-color: rgb(250, 250, 250);
  background-color: rgb(238, 238, 237);
  margin: 3rem auto;
  padding: 1rem 2rem;
  max-width: 800px;
  width: 80%;
  border-radius: 3px;
  box-shadow: 0px 0px 3px 3px white;
}

.card:hover {
  box-shadow: 0px 0px 9px 9px white;
}
