.App {
  text-align: center;
  box-sizing: content-box;
  background-color: rgb(238, 238, 237);
  height: 100vh;
  width: 100vw;
  color: rgb(80, 80, 80);
  overflow: scroll;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.wrapper {
  width: 80%;
  margin: 0 auto;
  height: 100%;
  position: relative;
  z-index: 1;
  /* display: flex;
  flex-direction: column; */
  /* justify-content:space-between; */
}

hr {
  height: 1px;
  background-color: #ccc;
  border: none;
  margin: 1rem 0;
}

ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

#back-to-top {
  position: fixed;
  bottom: 6rem;
  right: 10%;
  width: 3rem;
  height: 3rem;
}

#back-to-top:hover {
  cursor: pointer;
}

#nothing {
  height: 0;
  margin: 0;
  padding: 0;
}
