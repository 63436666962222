.section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: calc(100vh - 10rem);
}

.article {
  text-align: start;
  width: 50%;
}

.svg {
  fill: rgb(80, 80, 80);
  margin-inline-end: 0.9rem;
}

.img_div {
  width: 40%;
  aspect-ratio: 1 / 1;
  border-radius: 50%;
  overflow: hidden;
}

.img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.name {
  color: rgb(60, 60, 60);
  font-size: 6rem;
  font-weight: 700;
  margin: 1rem 0;
}

.link {
  text-decoration: none;
  color: inherit;
}
